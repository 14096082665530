import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Dashboard } from './layouts/dashboard/Dashboard';
import { NotFoundPage } from './pages/notfound/NotFoundPage';
import './App.css';

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
}

export default App;
