import { Header } from './Header';
import { SidebarNav } from './SidebarNav';
import { MobileNav } from './MobileNav';
import { BrowserView, MobileView } from 'react-device-detect';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';

const drawerWidth = 240;

export function Dashboard() {

    return <Box sx={{ display: 'flex' }}>
        <Header navWidth={drawerWidth} goTo={console.log} />
        <BrowserView>
            <SidebarNav navWidth={drawerWidth} />
        </BrowserView>
        <MobileView>
            <MobileNav />
        </MobileView>
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Toolbar />
            <Outlet />
        </Box>
    </Box>;
}