import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Paper from '@mui/material/Paper';

export function MobileNav() {
    return <Paper sx={{ position: 'fixed', bottom: 0, left: 1, right: 1 }} elevation={3}>
        <BottomNavigation
            showLabels
        >
            <BottomNavigationAction label="Dashboard" icon={<HomeIcon />}
                component={Link} to="/" />
            <BottomNavigationAction label="Locations" icon={<LocationOnIcon />} 
                component={Link} to="/locations" />
            <BottomNavigationAction label="Rooms" icon={<MeetingRoomIcon />} 
                component={Link} to="/rooms" />
            <BottomNavigationAction label="Games" icon={<SportsEsportsIcon />} 
                component={Link} to="/games" />
        </BottomNavigation>
    </Paper>;
}