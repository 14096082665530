import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Logout from '@mui/icons-material/Logout';
import { Typography } from '@mui/material';
import { BrowserView } from 'react-device-detect';


export function Header(props) {

    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleClose = () => {
        setMenuOpen(false);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }

    return <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
            <Box sx={{ 
                display: "flex", 
                alignItems: 'center',
                width: props.navWidth 
            }} >
                <BrowserView>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                </BrowserView>
                <img src="/logo512.png" alt="" height="30px" />
                <Typography variant="h5" component="div">
                    <nobr>&nbsp;Gateways Creative</nobr>
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small">
                        <Avatar sx={{ width: 40, height: 40 }}><PersonOutlineIcon /></Avatar>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <DropdownLink words="Settings" icon={<Settings fontSize="small" />}
                        path="/settings" />
                    <DropdownLink words="Logout" icon={<Logout fontSize="small" />}
                        path="/logout" />
                </Menu>
            </Box>
        </Toolbar>
    </AppBar>
}

function DropdownLink(props) {
    
    return <MenuItem component={Link} to={props.path}>
        <ListItemIcon>
            { props.icon }
        </ListItemIcon>
        { props.words }
    </MenuItem>;
}