import React from "react";
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';

export function SidebarNav(props) {

    return <Drawer
        variant="permanent"
        sx={{
            width:  props.navWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: props.navWidth, boxSizing: 'border-box' },
        }}
    >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List>
                <ListItem button key="1"
                    component={Link} to='/' >
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button key="2"
                    component={Link} to='/locations' >
                    <ListItemIcon><LocationOnIcon /></ListItemIcon>
                    <ListItemText primary="Locations" />
                </ListItem>
                <ListItem button key="3"
                    component={Link} to='/rooms' >
                    <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
                    <ListItemText primary="Rooms" />
                </ListItem>
                <ListItem button key="4"
                    component={Link} to='/games' >
                    <ListItemIcon><SportsEsportsIcon /></ListItemIcon>
                    <ListItemText primary="Games" />
                </ListItem>
            </List>
        </Box>
    </Drawer>

}